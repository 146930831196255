.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.containerA {
  max-width: 1920px;
  min-width: 375px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.text-stroke {
  -webkit-text-stroke-width: 4px;
  -webkit-text-stroke-color: #A99828;
}

.text-shadow-custom {
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.40), 0px 1px 0px rgba(0, 0, 0, 0.25);
}

.discord-icon:hover [fill="white"] {
  fill: black !important;
  transition: all 300ms ease-in-out;
}

.telegram-icon:hover [fill="white"] {
  fill: #2488FF !important;
  transition: all 300ms ease-in-out;
}

.x-icon:hover [fill="white"] {
  fill: black !important;
  transition: all 300ms ease-in-out;
}

header {
  position: absolute;
  width: 100%;
}

.video-react {
  background-color: unset !important;
}

.video-react .video-react-video {
  background: none !important;
}

.video-react-poster {
  background: transparent !important;
  background-image: none !important;
}

.video-react-button {
  left: 50% !important;
  top: 50% !important;
}

.video-react .video-react-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-react .video-react-button.video-react-play-control {
  width: auto;
  height: auto;
  padding: 15px;
  background-color: rgba(32, 167, 92, 0.86);
  transition: all 300ms ease-in-out;
}

.video-react .video-react-button.video-react-play-control img {
  width: 32px;
  height: 32px;
}

.video-react .video-react-button.video-react-play-control:hover {
  cursor: pointer;
  box-shadow: 0 0 29.4px 0 #98ffc6;
  background-color: #20a75c;
}

.video-react .video-react-control-bar {
  display: none;
}

button .video-react-button {
  display: none !important;
}

/* Hide default play button */
.video-react .video-react-play-control {
  display: none;
}

/* Hide control bar */
.video-react .video-react-control-bar {
  display: none;
}

.video-react-button {
  display: none !important;
}

.line-normal {
  line-height: normal;
}

.pattern-background {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.64) 0%, rgba(0, 0, 0, 0.64) 100%), url('./assets/img/shape/pattern.webp') lightgray -37.722px -109.351px / 40% 40% repeat;
}

.grecaptcha-badge {
  visibility: hidden !important;
}


.solar-container {
  display: block;
  padding: 8em 4em;
  margin: auto;
  color: white;
  font-family: Golos;
}

.solar-container h1 {
  font-size: 3em;
  margin-bottom: 30px;
  text-align: center;
  font-weight: bold;
}
.solar-container h2 {
  font-size: 3em;
  margin-top: 40px;
  margin-bottom: 40px;
  font-weight: bold;
}
.solar-container h3 {
  font-size: 2.5em;
  margin-bottom: 30px;
  font-weight: bold;
}
.solar-container h4 {
  font-size: 2.2em;
  margin-top: 30px;
  font-weight: bold;
}
.solar-container p {
  font-size: 1.5em;
  margin: 10px 0;
}

.solar-container ul {
  margin: 10px 0 10px 20px;
  list-style-type: circle;
  font-size: 1.5em;
}

.solar-container ol {
  margin: 10px 0 10px 20px;
  list-style-type: lower-roman;
  font-size: 1.5em;
}

.solar-container li {
  margin: 5px 0;
}

.solar-container a {
  color:#2488FF;
}

.solar-container span {
  font-weight: bold;
  font-size: 28px;
}

#scroll-container {
  border-radius: 5px;
  height: 200px;
  overflow: hidden;
}

#scroll-text {
  height: 100%;
  text-align: center;
  
  /* animation properties */
  -moz-transform: translateY(100%);
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  
  -moz-animation: my-animation 10s linear infinite;
  -webkit-animation: my-animation 10s linear infinite;
  animation: my-animation 10s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
  from { -moz-transform: translateY(100%); }
  to { -moz-transform: translateY(-130%); }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from { -webkit-transform: translateY(100%); }
  to { -webkit-transform: translateY(-130%); }
}

@keyframes my-animation {
  from {
    -moz-transform: translateY(100%);
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  to {
    -moz-transform: translateY(-130%);
    -webkit-transform: translateY(-130%);
    transform: translateY(-130%);
  }
}